import { useRef, useState } from "react";
import styled from "styled-components";
import useOutsideClickAlerter from "../../../../commons/click-outside-alert/ClickOutsideAlert";

const Container = styled.div`
    position: relative;
    width: 178px;
`;

const ActiveWorkPlanContainer = styled.div`
    background: #333;
    padding: 5px 15px 5px 15px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: ${props => props.isShowingWorkPlans ? '5px 5px 0px 0px' : '5px'};

    :hover{
        background: ${props => props.isShowingWorkPlans ? '' : '#444'};
    }
`

const ActiveWorkPlanContent = styled.span`
    text-overflow: ellipsis;
    white-space:nowrap;
    overflow:hidden;
`

const TriangleContainer = styled.div`
    transform: ${props => props.isShowingWorkPlans ? 'rotate(180deg)' : 'none'};
    margin-left: 5px;
`

const AvailableWorkPlansContainer = styled.div`
    width: 100%;
    background: #333;
    position: absolute;
    z-index: 1;

    /* border-style: solid; */
    /* border-width: 1px; */
    /* border-color: rgba(255,255,255,0.5); */
    /* border-top: none; */

`

const WorkPlanOption = styled.div`
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;

    :hover{
        background-color: rgba(255,255,255, 0.1);
    }
`

const OptionContent = styled.span`
    margin-left: 15px;
    text-overflow: ellipsis;
    white-space:nowrap;
    overflow:hidden;
`

function TasksWorkPlan(props) {

    props.workPlans.sort()
    const hasWorkPlans = props.workPlans && props.workPlans.length > 0;
    const [isShowingWorkPlans, setIsShowingWorkPlans] = useState(false);
    const wrapperRef = useRef(null);
    const title = 'Select a work plan';

    useOutsideClickAlerter(wrapperRef, () => setIsShowingWorkPlans(false));

    function onWorkPlanSelected(workPlanName) {
        setIsShowingWorkPlans(false);
        props.onWorkPlanSelected(workPlanName);
    }

    function resolveActiveWorkPlanTitle() {
        return props.activeWorkPlan ? props.activeWorkPlan : title
    }

    return (
        <Container ref={wrapperRef}>

            <ActiveWorkPlanContainer isShowingWorkPlans={isShowingWorkPlans} title={resolveActiveWorkPlanTitle()} onClick={() => setIsShowingWorkPlans(!isShowingWorkPlans)}>
                <ActiveWorkPlanContent>{resolveActiveWorkPlanTitle()}</ActiveWorkPlanContent>
                <TriangleContainer isShowingWorkPlans={isShowingWorkPlans}>▼</TriangleContainer>
            </ActiveWorkPlanContainer>

            {
                hasWorkPlans && isShowingWorkPlans ?
                    <AvailableWorkPlansContainer>
                        <WorkPlanOption onClick={() => setIsShowingWorkPlans(false) || props.onWorkPlanExit()} title={title}><OptionContent>{title}</OptionContent></WorkPlanOption>
                        {props.workPlans.map((workPlan, index) => <WorkPlanOption key={workPlan} onClick={() => onWorkPlanSelected(workPlan)} title={workPlan}><OptionContent>{workPlan}</OptionContent></WorkPlanOption>)}
                    </AvailableWorkPlansContainer>
                    : null
            }

        </Container>
    );
}

export default TasksWorkPlan;