import styled from 'styled-components'
import { useState } from 'react';

const Container = styled.div`
    background-color: transparent;
`;

const StyledButton = styled.button`
    cursor: ${props => !props.disabled ? 'pointer' : ''};
    border: none;
    border-radius: 0px 0px 0px 0px;
    padding: 10px;
    font-weight: bold;
    opacity: 0.9;
    min-width: 70px;
    background-color: ${props => {

        if (props.disabled) {
            return "#aaa";
        }

        return '#fff';
    }};
    color: '#fff';

    :hover{
        opacity: ${props => props.disabled ? '' : 1};
    }
`;

function Switch(props) {

    const leftValue = props.leftValue;
    const rightValue = props.rightValue;

    return (
        <Container>
            <StyledButton onClick={_ => props.onSelected(leftValue)} title={leftValue} disabled={props.selected === leftValue}>{leftValue}</StyledButton>
            <StyledButton onClick={_ => props.onSelected(rightValue)} title={rightValue} disabled={props.selected === rightValue}>{rightValue}</StyledButton>
        </Container>
    )
}

export default Switch;