import styled from 'styled-components'

const StyledButton = styled.button`
    cursor: ${props => !props.disabled ? 'pointer' : 'not-allowed'};
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    opacity: 0.8;
    min-width: 70px;
    background-color: ${props => {

        if (props.disabled) {
            return "#62686b";
        }

        return props.isConfirmation ? '#10ad2d' : 'transparent'
    }};
    color: ${props => props.isConfirmation ? '#fff' : 'initial'};

    :hover{
        opacity: ${props => props.disabled ? '' : 1};
    }
`;

function Button(props) {

    return (
        <StyledButton title={props.value} isConfirmation={props.isConfirmation} onClick={props.onClick} disabled={!props.enabled}>
            {props.value}
        </StyledButton>
    )
}

export default Button;