import styled from "styled-components";


const Container = styled.div`
    padding: 15px;
    background-color: #333;
    color: #fff;

    a{
        color: inherit;
    }
`

const FooterDivisor = styled.span`
    padding: 0px 15px 0px 15px;
`

const Logo = styled.span`
    font-family: 'Courgette', cursive;
    padding-right: 15px;
`

const Heart = styled.span`
    padding: 0px 5px 0px 5px;
`

function Footer() {
    return (
        <Container>
            <Logo>The Chill Work</Logo>
            Made with <Heart>❤️</Heart> by <a href="https://www.linkedin.com/in/victorhsr/">@victorhsr</a>

            <FooterDivisor>|</FooterDivisor>
            <a href="privacy.html" target="_blank">Privacy</a>
        </Container>
    )
}

export default Footer;