import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { confirmPrivacyPolicy, hasCofirmedPrivacyPolicy } from "../../service/PrivacyPolicyService";

const fadeIn = keyframes`
    0% {
		bottom: -300px;
	}

    70%{
        bottom: -300px;
    }

	100% {
		bottom: 0;
	}
`;

const Container = styled.div`
    background-color: #f4f4f6;
    color: #444;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    padding: 15px;
    display: ${props => props.accepted ? 'none' : 'flex'};
    animation-name: ${fadeIn};
    animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
    border-style: solid;
    border-color: #000;
    border-width: 2px;
    flex-direction: column;

    @media (min-width: 600px){
        width: 70vw;
    }

    transition: 1s;
`

const TextContainer = styled.div`
    padding-right: 15px;
    padding-bottom: 15px;
`

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;

    a{
        margin-right: 15px;
        text-decoration: none;
        color: #f4f4f6;
        background-color: #333;
        padding: 5px;
    }

    span{
        cursor: pointer;
        color: #f4f4f6;
        background-color: #333;
        padding: 5px 10px 5px 10px;
    }
`

function PrivacyPolicyConfirmation() {

    const [accepted, setAccepted] = useState(true);

    function onPolicyAccepted() {
        setAccepted(true);
        confirmPrivacyPolicy();
    }

    useEffect(() => {
        loadPrivacyPolicyResponse();
    }, []);

    const loadPrivacyPolicyResponse = async () => {
        const workPlanDefinitions = await hasCofirmedPrivacyPolicy();
        setAccepted(workPlanDefinitions);
    }

    return (
        <Container accepted={accepted}>
            <TextContainer>
                We use cookies to run advertisements and collect analytic data. By continuing to use our website without changing your cookie settings, you agree to our use of cookies in accordance with our Privacy Policy. <a href="/privacy.html">Learn More</a>
            </TextContainer>
            <ButtonsContainer>
                <a title="You'll be redirected out of the website" href="https://google.com">Deny</a>
                <span title="Accept our privacy policy and continue to our website" onClick={onPolicyAccepted}>OK</span>
            </ButtonsContainer>
        </Container>
    );
}

export default PrivacyPolicyConfirmation;