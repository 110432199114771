import styled from 'styled-components'

const Container = styled.div`
background: ${props => props.complete ? "#10ad2d" : "#D3143A"};
height: 25px;
width: 25px;
border-radius: 50%;
`;

const L = styled.div`
transform: rotate(45deg) scale(-1, 1);
font-size: 18px;
color: white;
font-weight: bold;
`;

function SuccessIcon(props) {

    return (
        <Container complete={props.complete}><L>L</L></Container>
    )
}

export default SuccessIcon;