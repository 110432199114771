
const PRIVACY_POLICY_KEY = 'PRIVACY_POLICY_CONFIRMED';
const EXPECTED_RESPONSE = '1';

async function confirmPrivacyPolicy() {
    localStorage.setItem(PRIVACY_POLICY_KEY, EXPECTED_RESPONSE);
}

async function hasCofirmedPrivacyPolicy() {
    return localStorage.getItem(PRIVACY_POLICY_KEY) === EXPECTED_RESPONSE;
}

export { confirmPrivacyPolicy, hasCofirmedPrivacyPolicy }