import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import AddCardButton from "../../components/add-card-button/AddCardButton";
import CardForm from "../../components/card-form/CardForm";
import CardHolder from "../../components/card-holder/CardHolder";
import { loadWorkPlans, saveWorkPlans } from "../../service/WorkPlanService";
import { swapItemsInArray } from "../../commons/arrays/Arrays";
import useOutsideClickAlerter from "../../commons/click-outside-alert/ClickOutsideAlert";
import TaskManagerOptions from "./options/TaskManagerOptions";

const Container = styled.div`
    height: 100%;
    position: relative;

    backdrop-filter: blur(6px) saturate(180%);
    -webkit-backdrop-filter: blur(6px) saturate(180%);
    background-color: rgba(17, 12, 12, 0.5);
    border-radius: 0px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    @media (min-width:600px) {
        border-radius: 12px;
        height: 90%;
    }

    @media (min-width:1025px) {
        height: 100%;
    }
`;

const CardHolderContainer = styled.div`
    height: 60%;
`;

const CardFormContainer = styled.div`
    position: absolute;
    width: 96%;
    bottom: 0px;
    margin-left: 2%;
`;

const TaskManagerFragment = React.forwardRef((props, ref) => {

    const [taskData, setTaskData] = useState({ taskInEdition: { index: -1, task: null }, orphanTasks: [], workPlans: {}, activeWorkPlan: null, isShowingCardForm: false });
    const cardFormRef = useRef(null);

    useOutsideClickAlerter(cardFormRef, onCardFormCancel);

    useEffect(() => {
        initWorkPlans();
    }, [props])

    useEffect(() => {
        if (taskData.taskInEdition.task == null) {
            saveWorkPlan();
        }
    }, [taskData])

    const initWorkPlans = async () => {
        const workPlanDefinitions = await loadWorkPlans();
        if (!workPlanDefinitions) {
            return;
        }

        setTaskData({ ...taskData, orphanTasks: workPlanDefinitions['orphan-tasks'], workPlans: workPlanDefinitions.workPlans, activeWorkPlan: workPlanDefinitions.active_workPlan })
    }

    function onTasksChanged(newTasks) {
        let newTaskData;

        if (!taskData.activeWorkPlan) {
            newTaskData = { ...taskData, orphanTasks: newTasks };
        } else {
            const newWorkPlans = { ...taskData.workPlans };
            newWorkPlans[taskData.activeWorkPlan].tasks = newTasks;
            newTaskData = { ...taskData, workPlans: newWorkPlans };
        }

        setTaskData(newTaskData);
    }

    function addTask(task) {
        let newTasks = resolveActiveTasks(taskData);

        newTasks.push(task);

        const newTaskData = { ...taskData, isShowingCardForm: false };

        if (taskData.taskInEdition.task != null) {
            newTasks = swapItemsInArray(newTasks, newTasks.length - 1, taskData.taskInEdition.index);
            newTaskData.taskInEdition = { index: -1, task: null }
        }

        if (!taskData.activeWorkPlan) {
            newTaskData.orphanTasks = newTasks;
        } else {
            newTaskData.workPlans[taskData.activeWorkPlan].tasks = newTasks;
        }

        setTaskData(newTaskData);
    }

    function onEditRequested(taskIndex) {
        const tasks = resolveActiveTasks(taskData);
        const taskToEdit = tasks.splice(taskIndex, 1)[0];

        let newTaskData = { ...taskData, taskInEdition: { index: taskIndex, task: taskToEdit }, isShowingCardForm: true };

        if (!taskData.activeWorkPlan) {
            newTaskData.orphanTasks = tasks;
        } else {
            taskData.workPlans[taskData.activeWorkPlan].tasks = tasks;
        }

        setTaskData(newTaskData);
    }

    function onCardFormCancel(event) {

        setTaskData(myTaskData => {

            if (event && myTaskData.taskInEdition.task != null) {
                event && event.preventDefault();
                event && event.stopPropagation();

                // eslint-disable-next-line no-restricted-globals
                const promptResult = confirm('Do you want to cancel the edition? The changes will be lost');
                if (promptResult == false) {
                    return myTaskData;
                }
            }

            let taskInEdition = myTaskData.taskInEdition;
            let newTasks = [...resolveActiveTasks(myTaskData)];

            if (taskInEdition.task != null) {
                newTasks.push(taskInEdition.task);
                newTasks = swapItemsInArray(newTasks, newTasks.length - 1, taskInEdition.index);

                taskInEdition = { index: -1, task: null }
            }

            let newTaskData = { ...myTaskData, taskInEdition, isShowingCardForm: false };

            if (!myTaskData.activeWorkPlan) {
                newTaskData.orphanTasks = newTasks;
            } else {
                newTaskData.workPlans[myTaskData.activeWorkPlan].tasks = newTasks;
            }

            return newTaskData;
        });
    }

    function cleanTasks() {
        const newTasks = resolveActiveTasks(taskData).map(task => { return { ...task, executedRounds: 0 } });
        let newTaskData = { ...taskData };

        if (!taskData.activeWorkPlan) {
            newTaskData.orphanTasks = newTasks;
        } else {
            newTaskData.workPlans[taskData.activeWorkPlan].tasks = newTasks;
        }

        setTaskData(newTaskData);
    }

    function onWorkPlanSelected(workPlanName) {
        if (!taskData.activeWorkPlan && taskData.orphanTasks.length > 0) {
            // eslint-disable-next-line no-restricted-globals
            const confirmationResult = confirm('You have tasks in the current draft, if you go to another work plan before saving you will lose the current tasks, do you want to proceed anyway?');
            if (!confirmationResult) {
                return;
            }
        }

        setTaskData({ ...taskData, activeWorkPlan: workPlanName });
    }

    function onWorkPlanAdded(workPlanName) {
        if (taskData.workPlans[workPlanName]) {
            alert(`You already have a work plan with name "${workPlanName}"`);
        } else {
            const newTaskData = { ...taskData, orphanTasks: [] };
            newTaskData.workPlans = { ...taskData.workPlans };
            newTaskData.workPlans[workPlanName] = {
                name: workPlanName,
                tasks: [...taskData.orphanTasks]
            };
            newTaskData.activeWorkPlan = workPlanName;

            setTaskData(newTaskData);
        }
    }

    function saveWorkPlan() {
        saveWorkPlans({
            "active_workPlan": taskData.activeWorkPlan,
            "workPlans": taskData.workPlans,
            "orphan-tasks": taskData.orphanTasks
        })
    }

    function resolveActiveTasks(taskDataToCheck) {
        if (!taskDataToCheck.activeWorkPlan) {
            return taskDataToCheck.orphanTasks;
        }

        return taskDataToCheck.workPlans[taskDataToCheck.activeWorkPlan].tasks;
    }

    function onWorkPlanExit() {
        setTaskData({ taskInEdition: { index: -1, task: null }, orphanTasks: [], workPlans: { ...taskData.workPlans }, activeWorkPlan: null });
    }

    function onRemoveAllTasks() {
        let newTaskData = { ...taskData };

        if (!taskData.activeWorkPlan) {
            newTaskData.orphanTasks = [];
        } else {
            newTaskData.workPlans[taskData.activeWorkPlan].tasks = [];
        }

        setTaskData(newTaskData);
    }

    function removeActiveWorkPlan() {
        const newTaskData = { ...taskData, activeWorkPlan: null };
        delete newTaskData.workPlans[taskData.activeWorkPlan];

        setTaskData(newTaskData);
    }

    function onWorkPlanRenamed(newName) {
        const newTaskData = { ...taskData, activeWorkPlan: newName };
        newTaskData.workPlans = { ...taskData.workPlans };
        delete newTaskData.workPlans[taskData.activeWorkPlan];

        newTaskData.workPlans[newName] = taskData.workPlans[taskData.activeWorkPlan];
        setTaskData(newTaskData);
    }

    function loadExternalWorkPlan(newWorkPlan) {
        if (taskData.orphanTasks.length > 0 || Object.keys(taskData.workPlans).length > 0) {
            // eslint-disable-next-line no-restricted-globals
            const confirmResult = confirm('All your current tasks and workPlans will be replaced, do you want to proceed?');
            if (!confirmResult) {
                return;
            }
        }

        saveWorkPlans(newWorkPlan);
        initWorkPlans();
    }

    useImperativeHandle(ref, () => ({

        increaseTaskProgress() {
            const newTaskData = { ...taskData };
            let indexToIncrement;
            let taskToIncrement;
            let newTask;
            let newTasks;

            if (!taskData.activeWorkPlan) {
                indexToIncrement = taskData.orphanTasks.findIndex(task => {
                    return task.executedRounds < task.expectedRounds
                });
                if (indexToIncrement < 0) {
                    indexToIncrement = 0;
                }

                newTasks = [...taskData.orphanTasks];
                newTaskData.orphanTasks = newTasks;
            } else {
                newTasks = [...taskData.workPlans[taskData.activeWorkPlan].tasks];
                indexToIncrement = newTasks.findIndex(task => task.executedRounds < task.expectedRounds);
                if (indexToIncrement < 0) {
                    indexToIncrement = 0;
                }
                newTaskData.workPlans[taskData.activeWorkPlan].tasks = newTasks;
            }

            if (newTasks.length === 0) return;

            taskToIncrement = newTasks[indexToIncrement];
            newTask = { ...taskToIncrement, executedRounds: taskToIncrement.executedRounds + 1 };
            newTasks[indexToIncrement] = newTask;

            setTaskData(newTaskData);
        }

    }));

    return (
        <Container ref={ref}>
            <TaskManagerOptions onNewWorkPlanLoaded={loadExternalWorkPlan} onWorkPlanRenamed={onWorkPlanRenamed} removeWorkPlan={removeActiveWorkPlan} onRemoveAllTasks={onRemoveAllTasks} onWorkPlanExit={onWorkPlanExit} onAddWorkPlan={onWorkPlanAdded} workPlans={Object.keys(taskData.workPlans)} activeWorkPlan={taskData.activeWorkPlan} onWorkPlanSelected={onWorkPlanSelected} onCleanTask={cleanTasks} />

            <CardHolderContainer>
                <CardHolder cards={resolveActiveTasks(taskData)} enabled={taskData.taskInEdition.task == null} onEditRequested={onEditRequested} onCardsChanged={onTasksChanged} />
            </CardHolderContainer>
            <CardFormContainer>
                {
                    taskData.isShowingCardForm ? <CardForm ref={cardFormRef} expectedRounds={taskData.taskInEdition.task?.expectedRounds} title={taskData.taskInEdition.task?.title} executedRounds={taskData.taskInEdition.task?.executedRounds} onCardsChanged={addTask} onCancel={() => onCardFormCancel()} />
                        :
                        <AddCardButton addNewTask={() => setTaskData({ ...taskData, isShowingCardForm: true })} />
                }
            </CardFormContainer>
        </Container>
    );
});

export default TaskManagerFragment;