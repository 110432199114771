import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import useOutsideClickAlerter from "../../../commons/click-outside-alert/ClickOutsideAlert";
import { OptionContent, OptionsContainer, OptionsItem, OptionsItemWithConfirmation } from "../../../components/task-card/card-option/CardOption";
import { exportWorkPlans, loadExternalWorkPlan, loadWorkPlans } from "../../../service/WorkPlanService";
import TasksWorkPlan from "./workPlans/TasksWorkPlan";

const Container = styled.div`
    width: 100%;
    color: #fff;
    margin-bottom: 15px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 15px;
    border-bottom-color: rgba(255,255,255,0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
`;

const SliderIcon = styled.img`
    width: 1.5em;
    cursor: pointer;
`
const TasksTitle = styled.span`
    margin-left: 30px;
    font-weight: bold;
    text-align: left;
`

const OptionsButtom = styled.div`
    align-content: flex-end;
    justify-content: end;
    position: relative;
`

const OptionsButtomContainer = styled.div`
    margin-right: 30px;
`;

const OptionLineBreaker = styled.div`
    width: 80%;
    height: 0px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0, 0.2);
`
function TaskManagerOptions(props) {

    const [isShowingOptions, setIsShowingOptions] = useState(false);
    const wrapperRef = useRef(null);
    const saveWorkPlan = 'Save work plan';
    const renameWorkPlanContent = 'Rename work plan';
    const removeWorkPlanContent = 'Remove work plan';
    const workPlanNameContent = 'Please enter the name of the work plan';
    const importWorkPlanContent = 'Import work plan';
    const exportWorkPlanContent = 'Export work plan';
    const clearTasksContent = 'Clear tasks';
    const removeAllTasksContent = "Remove all tasks";

    useOutsideClickAlerter(wrapperRef, resetOptions);

    function resetOptions() {
        setIsShowingOptions(false);
    }

    function addWorkPlan() {
        // eslint-disable-next-line no-undef
        const workPlanName = showSafePrompt(workPlanNameContent);
        workPlanName && props.onAddWorkPlan(workPlanName);
    }

    function onRenameWorkPlan() {
        // eslint-disable-next-line no-undef
        const workPlanName = showSafePrompt(workPlanNameContent);
        workPlanName && props.onWorkPlanRenamed(workPlanName);
    }

    function showSafePrompt(content) {
        const result = prompt(content);

        if (result != null && result.trim().length == 0) {
            return alert('Invalid value');
        }

        return result;
    }

    async function loadWorkPlan() {
        try {
            const content = await loadExternalWorkPlan();
            props.onNewWorkPlanLoaded(content);
        } catch (error) {
            alert('It was not possible to load your external work plan, make sure you are using the correct file');
        } finally {
            resetOptions();
        }
    }

    return (
        <Container>
            <TasksTitle>Work plan</TasksTitle>

            <TasksWorkPlan onWorkPlanExit={props.onWorkPlanExit} onWorkPlanSelected={props.onWorkPlanSelected} activeWorkPlan={props.activeWorkPlan} workPlans={props.workPlans} />

            <OptionsButtomContainer>
                <OptionsButtom ref={wrapperRef}>
                    <SliderIcon onClick={() => setIsShowingOptions(!isShowingOptions)} title="Tasks Options" src="/icons/slider.svg" alt="slider-icon" />
                    {isShowingOptions ?
                        <OptionsContainer>
                            {
                                props.activeWorkPlan ? null : <OptionsItem title={saveWorkPlan} onClick={addWorkPlan}><OptionContent>{saveWorkPlan}</OptionContent></OptionsItem>
                            }
                            {
                                props.activeWorkPlan ? <OptionsItem title={renameWorkPlanContent} onClick={onRenameWorkPlan}><OptionContent>{renameWorkPlanContent}</OptionContent></OptionsItem> : null
                            }
                            {
                                props.activeWorkPlan ? <OptionsItemWithConfirmation title={removeWorkPlanContent} onClick={() => resetOptions() || props.removeWorkPlan()}><OptionContent>{removeWorkPlanContent}</OptionContent></OptionsItemWithConfirmation> : null
                            }
                            <OptionsItem title={importWorkPlanContent} onClick={loadWorkPlan}><OptionContent>{importWorkPlanContent}</OptionContent></OptionsItem>
                            <OptionsItem title={exportWorkPlanContent} onClick={() => resetOptions() || exportWorkPlans()}><OptionContent>{exportWorkPlanContent}</OptionContent></OptionsItem>
                            <OptionLineBreaker />
                            <OptionsItemWithConfirmation title={clearTasksContent} onClick={() => resetOptions() || props.onCleanTask()}><OptionContent>{clearTasksContent}</OptionContent></OptionsItemWithConfirmation>
                            <OptionsItemWithConfirmation title={removeAllTasksContent} onClick={() => resetOptions() || props.onRemoveAllTasks()}><OptionContent>{removeAllTasksContent}</OptionContent></OptionsItemWithConfirmation>
                        </OptionsContainer> : null
                    }
                </OptionsButtom>
            </OptionsButtomContainer>
        </Container >
    );
}

export { OptionsButtom };
export default TaskManagerOptions;