import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: 15px;
`;

const PomodoroButtom = styled.button`
    height: 2.2em;
    padding-left: 10px;
    padding-right: 10px;

    cursor: ${props => !props.isPomodoroActive ? 'pointer' : 'initial'};
    color:#fff;
    border:none;
    border-radius: 5px;
    background: ${props => props.isPomodoroActive ? 'rgba(0,0,0,0.2)' : 'none'};
    font-weight: ${props => props.isPomodoroActive ? 'bold' : 'initial'};
    font-size: 1em;

    :hover{
        background-color: ${props => !props.isPomodoroActive ? 'rgba(0,0,0,0.1)' : ''};
    }
`;

const BreakButtom = styled.button`
    margin-left: 15px;
    margin-right: 15px;
    height: 2.2em;
    padding-left: 10px;
    padding-right: 10px;

    cursor: ${props => props.isPomodoroActive ? 'pointer' : 'initial'};
    color:#fff;
    border:none;
    border-radius: 5px;
    background: ${props => !props.isPomodoroActive ? 'rgba(0,0,0,0.2)' : 'none'};
    font-weight: ${props => !props.isPomodoroActive ? 'bold' : 'initial'};
    font-size: 1em;
    
    :hover{
        background-color: ${props => props.isPomodoroActive ? 'rgba(0,0,0,0.1)' : ''};
    }
`;

function CountdownSelector(props) {

    function onBreakRequested() {
        if (props.isPomodoroActive && props.isCounting) {
            // eslint-disable-next-line no-restricted-globals
            const confirmed = confirm('Do you want to interrupt the current pomodoro?');
            if (confirmed) {
                props.onBreak();
            }
        } else {
            props.onBreak();
        }
    }

    function onPomodoroRequested() {
        if (!props.isPomodoroActive && props.isCounting) {
            // eslint-disable-next-line no-restricted-globals
            const confirmed = confirm('Do you want to interrupt the current break time?');
            if (confirmed) {
                props.onPomodoro();
            }
        } else {
            props.onPomodoro();
        }
    }

    return (
        <Container>
            <PomodoroButtom title="Pomodoro Countdown" onClick={onPomodoroRequested} isPomodoroActive={props.isPomodoroActive} disabled={props.isPomodoroActive}>Pomodoro</PomodoroButtom>
            <BreakButtom title="Break Countdown" onClick={onBreakRequested} isPomodoroActive={props.isPomodoroActive} disabled={!props.isPomodoroActive}>Break</BreakButtom>
        </Container>
    );
}

export default CountdownSelector;