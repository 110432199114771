import { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/footer/footer";
import PrivacyPolicyConfirmation from "../components/privacy-policy-confirmation/PrivacyPolicyConfirmation";
import AboutPage from "./AboutPage";
import LofiPage from "./LofiPage";
import TasksPage from "./TasksPage";

const Container = styled.div`
    position: relative;
`;

const LogoContainer = styled.div`
    color: ${props => props.haveSrolled ? '#444444' : '#fff'};
    position: ${props => props.haveSrolled ? 'fixed' : 'absolute'};
    font-size: 2.5rem;
    letter-spacing: 1px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    font-family: 'Courgette', cursive;
    padding: ${props => props.haveSrolled ? '1rem 0 1rem 0' : '2rem 0 1rem 0'};;
    background-color: ${props => props.haveSrolled ? '#f4f4f6' : ''};
    z-index: 2;
    transition: 0.3s ease;
    box-shadow: ${props => props.haveSrolled ? '0 3px 5px rgba(57, 63, 72, 0.3);' : ''};
`

function HomePage() {

    const [haveSrolled, setHaveScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', onScrollEvent);
        return () => window.removeEventListener('scroll', onScrollEvent);
    }, [])

    function onScrollEvent() {
        setHaveScrolled(window.scrollY > 0)
    }

    return (
        <Container>
            <LogoContainer haveSrolled={haveSrolled}>The Chill Work</LogoContainer>
            <TasksPage />
            <LofiPage />
            <AboutPage />
            <PrivacyPolicyConfirmation />
            <Footer />
        </Container>
    );
}

export default HomePage;