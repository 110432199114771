import styled from "styled-components";

const Container = styled.div`
    padding: 50px 15px 25px 15px;
    color: #444;

    @media (min-width:600px) {
        padding: 50px 0px 25px 0px;
    }
`

const H1 = styled.h1`
    text-align: center;
    width: 100%;

    @media (min-width: 600px){
        width: 70vw;
    }

    @media (min-width: 1025px){
        width: 50vw;
    }
`

const H2 = styled.h2`
`

const P = styled.p`
    font-size: 1.2rem;
    line-height: 2rem;
`

const SubjectContainer = styled.div`
    width: 100%;

    @media(min-width:600px){
        width: 70vw;
    }

    @media (min-width: 1025px){
        width: 50vw;
    }
`

const A = styled.a``

const SubjectsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
`

const EffectSentence = styled.span`
    display: block;
    padding-top: 10px;
    font-weight: bold;
`

const OL = styled.ol`
    font-size: 1rem;
    padding-left: 1.2rem;
`

const UL = styled.ul`
    font-size: 1rem;
    padding-left: 1.2rem;
`

const LI = styled.li`
    padding-left: 3px;
    line-height: 2rem;
`

const Bold = styled.span`
    font-weight: bold;
`
const Underline = styled.span`
    text-decoration: underline;
`

const TitleContaine = styled.div`
    display: flex;
    justify-content: center;
`

function AboutPage(props) {
    return (
        <Container>
            <TitleContaine>
                <H1>Boost your productivity and make time and work management more chill</H1>
            </TitleContaine>
            <SubjectsContainer>
                <SubjectContainer>
                    <H2>The Chill Work</H2>
                    <P>The Chill Work is a web application designed to assist people, like you and me, who want to enhance their productivity and time management while maintaining a relaxed approach. This app is inspired by <A href="https://en.wikipedia.org/wiki/Pomodoro_Technique" target="_blank">The Pomodoro Technique</A>, a time management method developed by Francesco Cirillo. <br /> <EffectSentence>I hope to assist you with your studies, work, coding sessions, writing, or reading!</EffectSentence></P>
                </SubjectContainer>
                <SubjectContainer>
                    <H2>What is the Pomodoro Technique?</H2>
                    <P>The Pomodoro Technique is a time management method developed by Francesco Cirillo in the late 1980s, applicable in various contexts such as studying, working, or even during leisure activities. This technique involves dividing our time into two segments: the focus time, typically lasting 25 to 30 minutes, and the break time, which can range from short breaks of about 5 minutes to longer breaks lasting around 15 minutes. Following a focus session, it's important to take a short break to recharge and regain focus. However, if one is feeling particularly fatigued, opting for a longer break is also an option.</P>
                </SubjectContainer>
                <SubjectContainer>
                    <H2>How to use the Pomodoro Timer?</H2>
                    <OL>
                        <LI><Bold>Add the tasks</Bold> you want to work on (you can load them from a work plan as well)</LI>
                        <LI><Bold>Set how many <Underline>focus time</Underline> you want to put in each activity</Bold> (the <Underline>Expected</Underline> field in the task form)</LI>
                        <LI><Bold>Start the Pomodoro Timer</Bold> (the focus time will be accounted to the first pending task, you can reorder the tasks priority drag and dropping them)</LI>
                        <LI><Bold>Take a break</Bold> (after a focus time, the timer will switch to a break time and vice versa)</LI>
                        <LI><Bold>Repeat</Bold> (until you get your tasks done)</LI>
                    </OL>
                </SubjectContainer>
                <SubjectContainer>
                    <H2>How to use the "Work plan" Functionality?</H2>
                    <P>A work plan is a set of tasks that you want to work on in a session. For example, you could have a work plan for each day of the week, each with its own tasks. These are the options for you to manage work plans</P>
                    <UL>
                        <LI><Bold>Add Work plan</Bold> - you can save the tasks you were working on in a work plan, and reuse them later</LI>
                        <LI><Bold>Rename Work plan</Bold> - you can put any name you want in your work plan</LI>
                        <LI><Bold>Remove Work plan</Bold> - do you dislike a work plan? You can remove it</LI>
                        <LI><Bold>Export Work plan</Bold> - are you going to use another device or browser? You can export your work plans and reuse them</LI>
                        <LI><Bold>Import Work plan</Bold> - replace your work plans by an external one</LI>
                    </UL>
                </SubjectContainer>
            </SubjectsContainer>
        </Container>
    )
}

export default AboutPage;