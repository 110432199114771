import styled from "styled-components";

const LofiRecommendationContainer = styled.div`
    position: absolute;
    bottom: 35px;
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Courgette', cursive;
    font-size: 1.2rem;
`
const LofiTrianglesContainer = styled.div`
    margin-top: 15px;
    line-height: 1rem;
    position: relative;
    width: 100%;
`

const LofiTriangles = styled.div`
    position:absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    transform: rotate(90deg);
`

function LofiRecommendation(props) {
    return (
        <LofiRecommendationContainer>
            Lofi & Raining
            <LofiTrianglesContainer>
                <LofiTriangles>{'⫸'}</LofiTriangles>
            </LofiTrianglesContainer>
        </LofiRecommendationContainer>
    )
}

export default LofiRecommendation;