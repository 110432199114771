import styled from "styled-components"
import { useEffect, useState } from "react";

const InputNumber = styled.input`
    background-color: #EAEAEC;
    border: none;
    font-size: 0.95em;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 2.5em;
    text-align: center;

    :focus{
        outline: none;
    }

    @media (min-width:480px){
        text-align: right;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Title = styled.span`
    margin-bottom: 0.3em;
`

function RoundInput(props) {

    const [rounds, setRounds] = useState(0);

    useEffect(() => {
        setRounds(props.rounds);
    }, [props])

    function onRoundChange(newRounds) {
        if (!newRounds) {
            newRounds = 0;
        }

        setRounds(newRounds);
        props.onRoundChange(newRounds);
    }

    return (
        <Container title={props.hoverTitle}>
            <Title>{props.title}</Title>
            <InputNumber type="number" min={1} value={rounds} onChange={evt => onRoundChange(parseInt(evt.target.value))} />
        </Container>
    )
}

export default RoundInput;