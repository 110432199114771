import { useRef, useState } from 'react';
import styled from 'styled-components'
import useOutsideClickAlerter from '../../../commons/click-outside-alert/ClickOutsideAlert';

const Container = styled.div`
    width: calc(100% - 7px);
    position: relative;
`;

const MenuContainer = styled.div`
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: ${props => props.isShowingOptions ? 'rgba(255,255,255, 0.8)' : 'rgba(255,255,255, 0.5)'};
    font-size: 1.45rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 6px;
    color: ${props => props.isShowingOptions ? 'rgba(255,255,255, 0.8)' : 'rgba(255,255,255, 0.5)'};

    :hover{
        color: rgba(255,255,255,0.8);
        border-color: rgba(255,255,255,0.8);
    }
`;

const OptionsContainer = styled.div`
    background-color: #fff;
    position: absolute;
    width: ${props => props.width ? props.width : '145px'};
    right: 15px;
    border-style: solid;
    border-color: rgba(0,0,0,0.3);
    border-width: 1px;
    color:#444444;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const OptionsItem = styled.div`
    height: 35px;
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    font-size: 0.88rem;

    :hover{
        background-color: rgba(0,0,0, 0.1);
    }
`;

const OptionContent = styled.span`
    padding-left: 15px;
    font-weight: ${props => props.bold ? 'bold' : 'initial'};
`;

function OptionsItemWithConfirmation(props) {

    const [isWaitingForConfirmation, setIsWaitingForConfirmation] = useState(false);

    function handleOnClick() {
        if (isWaitingForConfirmation) {
            props.onClick();
        } else {
            setIsWaitingForConfirmation(true);
        }
    }

    return (
        <OptionsItem {...props} onClick={handleOnClick}>
            {isWaitingForConfirmation ? <OptionContent bold>Click to confirm</OptionContent> : props.children}
        </OptionsItem>
    )
}

function CardOption(props) {

    const [isShowingOptions, setIsShowingOptions] = useState(false);
    const wrapperRef = useRef(null);

    useOutsideClickAlerter(wrapperRef, resetOptions);

    function resetOptions() {
        setIsShowingOptions(false);
    }

    return (
        <Container ref={wrapperRef}>
            <MenuContainer title="Options" onClick={() => isShowingOptions ? resetOptions() : setIsShowingOptions(!isShowingOptions)} isShowingOptions={isShowingOptions}>⠇</MenuContainer>
            {
                isShowingOptions ? <OptionsContainer>
                    <OptionsItemWithConfirmation title="Remove" onClick={() => resetOptions() || props.onRemove()}><OptionContent>Remove</OptionContent></OptionsItemWithConfirmation>
                    <OptionsItem title="Edit" onClick={() => resetOptions() || props.onEdit()}><OptionContent>Edit</OptionContent></OptionsItem>
                </OptionsContainer> : null
            }
        </Container>
    )
}

export { OptionsContainer, OptionsItem, OptionContent, OptionsItemWithConfirmation }
export default CardOption;