import styled from 'styled-components'

const Button = styled.button`
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    border-style: none;
    background-color: transparent;
    color: #fff;
    display: flex;
    justify-content:center;
    align-items: center;
    left: auto !important;
    
    :hover{
        background-color: rgba(255, 255, 255, 0.03);
    }
`

const Title = styled.span`
    margin-left: 0.6em;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1.2em;
`

const PlusSign = styled.span`
    font-weight: bold;
    font-size: 2em;
`;

function AddCardButton(props) {

    return (
        <Button title='Add a new task' onClick={props.addNewTask}><PlusSign>+</PlusSign> <Title>Add Task</Title></Button>
    )
}

export default AddCardButton;