import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    max-width: 970px;
    height: 90px;
    background-color: red;
    margin-top: 15px;
    color: #fff;
    
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.5rem;
    word-spacing: 1rem;
`

function Adsense(props) {
    return (
        // <Container>ADSENSE HERE!</Container>
        <div></div>
    )
}

export default Adsense;