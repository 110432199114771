import { useRef, useState } from "react";
import styled from "styled-components";
import useOutsideClickAlerter from "../../commons/click-outside-alert/ClickOutsideAlert";
import Countdown from "../../components/countdown/Countdown";
import { OptionContent, OptionsContainer, OptionsItem } from "../../components/task-card/card-option/CardOption";
import { OptionsButtom } from "../task-manager/options/TaskManagerOptions";
import CountdownSelector from "./countdown-selector/CountdownSelector";

const Container = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    color:#fff;
    backdrop-filter: blur(6px) saturate(180%);
    -webkit-backdrop-filter: blur(6px) saturate(180%);
    background-color: rgba(17, 12, 12, 0.5);
    border: 1px solid rgba(209, 213, 219, 0.3);
    border-radius: 0px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    @media (min-width:600px) {
        border-radius: 12px;
        height: 90%;
    }

    @media (min-width:1025px) {
        height: 100%;
    }
`;

const CountdownContaincer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ButtomContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

const StartButtom = styled.button`
    cursor: pointer;
    background-color: #fff;
    border: none;
    height: 3.5em;
    width: 10em;
    border-radius: 5px;
    font-weight: bolder;
    color: #16248E;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    opacity: 0.9;

    :hover{
        opacity: 1;
    }
`;

const ConfigContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 50px;
    top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
`;

const MotivationalSentenceContainer = styled.div`
    color: ${props => props.isCounting ? 'rgba(255,255,255, 0.7)' : '#fff'};
    width: 100%;
    text-align: center;
    font-weight: bolder;
    position: absolute;
    /* bottom: 5%; */
    margin-top: 15px;
    left: 0;
    font-family: 'Carter One', cursive;
    letter-spacing: 0.1em;
    font-size: 1.1em;
    text-decoration: ${props => props.isCounting ? 'line-through' : 'none'};
`;

const SliderIcon = styled.img`
    width: 1.5em;
    cursor: pointer;
`;

const CountdownSelectorContainer = styled.div``;

const OptionsButtomContainer = styled.div`
    margin-right: 30px;
`;

function CountdownFragment(props) {

    const SECONDS_TO_INCREMENT = 300;
    const BUTTON_SFX = new Audio('/sfx/button.mp3');
    const ALARM_SFX = new Audio('/sfx/alarm.mp3');

    const [countdownData, setCountdownData] = useState({
        isShowingOptions: false,
        isPomodoroActive: true,
        isCounting: false,
        pomodoroRoundSeconds: 1800,
        breakRoundSeconds: 300,
        currentRoundSeconds: 1800
    });

    const wrapperRef = useRef(null);

    useOutsideClickAlerter(wrapperRef, resetOptions);

    function resetOptions() {
        setCountdownData(currentCountdownData => { return { ...currentCountdownData, isShowingOptions: false } });
    }

    function onPomodoroCountdownDone() {
        ALARM_SFX.play();
        props.onCountdownDone(false);
        switchToBreak();
    }

    function onBreakCountdownDone() {
        ALARM_SFX.play();
        props.onCountdownDone(true);
        switchToPomodoro();
    }

    function switchToBreak() {
        setCountdownData({ ...countdownData, isCounting: false, isPomodoroActive: false, currentRoundSeconds: countdownData.breakRoundSeconds });
    }

    function switchToPomodoro() {
        setCountdownData({ ...countdownData, isCounting: false, isPomodoroActive: true, currentRoundSeconds: countdownData.pomodoroRoundSeconds });
    }

    function onIncreaseCountdown() {

        const newCurrentRoundSeconds = countdownData.currentRoundSeconds + SECONDS_TO_INCREMENT;

        runIfPomodoroIsActive(() => {
            setCountdownData({ ...countdownData, currentRoundSeconds: newCurrentRoundSeconds, pomodoroRoundSeconds: countdownData.pomodoroRoundSeconds + SECONDS_TO_INCREMENT });
        });

        runIfPomodoroIsNotActive(() => {
            setCountdownData({ ...countdownData, currentRoundSeconds: newCurrentRoundSeconds, breakRoundSeconds: countdownData.breakRoundSeconds + SECONDS_TO_INCREMENT });
        });
    }

    function onDecreaseCountdown() {

        let newCurrentRoundSeconds = countdownData.currentRoundSeconds - SECONDS_TO_INCREMENT;
        if (newCurrentRoundSeconds <= 0) {
            newCurrentRoundSeconds = SECONDS_TO_INCREMENT;
        }

        runIfPomodoroIsActive(() => {
            let pomodoroRoundSeconds = countdownData.pomodoroRoundSeconds - SECONDS_TO_INCREMENT;
            if (pomodoroRoundSeconds <= 0) {
                pomodoroRoundSeconds = SECONDS_TO_INCREMENT;
            }

            setCountdownData({ ...countdownData, currentRoundSeconds: newCurrentRoundSeconds, pomodoroRoundSeconds });
        });

        runIfPomodoroIsNotActive(() => {
            let breakRoundSeconds = countdownData.breakRoundSeconds - SECONDS_TO_INCREMENT;
            if (breakRoundSeconds <= 0) {
                breakRoundSeconds = SECONDS_TO_INCREMENT;
            }

            setCountdownData({ ...countdownData, currentRoundSeconds: newCurrentRoundSeconds, breakRoundSeconds });
        });
    }

    function runIfPomodoroIsActive(callback) {
        if (countdownData.isPomodoroActive) {
            callback();
        }
    }

    function runIfPomodoroIsNotActive(callback) {
        if (!countdownData.isPomodoroActive) {
            callback();
        }
    }

    function switchIsShowing() {
        setCountdownData({ ...countdownData, isShowingOptions: !countdownData.isShowingOptions });
    }

    function switchIsCounting() {
        BUTTON_SFX.play();
        const isCounting = !countdownData.isCounting;
        setCountdownData({ ...countdownData, isCounting });
    }

    return (
        <Container>
            <ConfigContainer>
                <CountdownSelectorContainer>
                    <CountdownSelector onPomodoro={switchToPomodoro} onBreak={switchToBreak} isCounting={countdownData.isCounting} isPomodoroActive={countdownData.isPomodoroActive} />
                </CountdownSelectorContainer>

                <OptionsButtomContainer>
                    <OptionsButtom ref={wrapperRef}>
                        <SliderIcon onClick={switchIsShowing} title="Timer Options" src="/icons/slider.svg" alt="slider-icon" />
                        {
                            countdownData.isShowingOptions ?
                                <OptionsContainer width="160px">
                                    <OptionsItem title="Increase Countdown" onClick={onIncreaseCountdown}><OptionContent>Increase Countdown</OptionContent></OptionsItem>
                                    <OptionsItem title="Decrease Countdown" onClick={onDecreaseCountdown}><OptionContent>Decrease Countdown</OptionContent></OptionsItem>
                                </OptionsContainer> : null
                        }
                    </OptionsButtom>
                </OptionsButtomContainer>
            </ConfigContainer>

            {countdownData.isPomodoroActive ?
                <div>
                    <CountdownContaincer>
                        <Countdown active={countdownData.isCounting} seconds={countdownData.currentRoundSeconds} onDone={onPomodoroCountdownDone} />
                    </CountdownContaincer>
                    <ButtomContainer>
                        <StartButtom onClick={switchIsCounting} title={countdownData.isCounting ? 'Stop Pomodoro' : 'Start Pomodoro'}>
                            {countdownData.isCounting ? 'Stop' : 'Start'}
                        </StartButtom>
                    </ButtomContainer>
                    <MotivationalSentenceContainer isCounting={countdownData.isCounting}>Let's Work!</MotivationalSentenceContainer>
                </div>
                :
                <div>
                    <CountdownContaincer>
                        <Countdown active={countdownData.isCounting} seconds={countdownData.currentRoundSeconds} onDone={onBreakCountdownDone} />
                    </CountdownContaincer>
                    <ButtomContainer>
                        <StartButtom onClick={switchIsCounting} title={countdownData.isCounting ? 'Stop Break' : 'Start Break'}>
                            {countdownData.isCounting ? 'Stop' : 'Start'}
                        </StartButtom>
                    </ButtomContainer>
                    <MotivationalSentenceContainer isCounting={countdownData.isCounting}>Break time!</MotivationalSentenceContainer>
                </div>
            }
        </Container>
    );
}

export default CountdownFragment;