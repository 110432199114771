import { useCallback, useRef } from 'react';
import styled from 'styled-components'
import Adsense from '../components/adsense/Adsense';
import ConfeeLink from '../components/coffee-link/ConfeeLink';
import LofiRecommendation from '../components/lofi-recommendation/LofiRecommendation';
import CountdownFragment from '../fragments/countdown/CountdownFragmanet';
import TaskManagerFragment from '../fragments/task-manager/TaskManagerFragment';

const Container = styled.div`
    /* background-image: url('/bg/tokyo-2.jpg'), linear-gradient(#eb01a5, #d13531);  */
    background: ${props => `linear-gradient(
    to bottom,
    rgba(51,51,51, 0.2),
    rgba(0,0,0, 0)
  ),url('${props.bg}');`};

    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    height: fit-content;

    @media (min-width: 600px) {
        height: fit-content;
    }

    @media  (min-width:1025px) {
        height: 100vh;
    }
`;

const TaskManagerContainer = styled.div`
    height: 50vh;
    width: 100%;

    @media (min-width: 600px) {
        width: 80%;
    }

    @media (min-width:1025px) {
        width: 38vw;
    }
`;

const CountdownContainer = styled.div`
    height: 50vh;
    width: 100%;
    margin-bottom: 50px;

    @media (min-width: 600px) {
        width: 80%;
        margin-bottom: 50px;
    }

    @media (min-width:1025px) {
        width: 38vw;
        margin-bottom: 0px;
    }
`
const FragmentsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    flex-direction: column;
    margin-top: 90px;
    
    @media (min-width: 600px) {
        width: 100%;
        flex-direction: column;
        margin-top: 90px;
    }

    @media (min-width:1025px) {
        width: auto;
        flex-direction: row;
        margin-top: 0px;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 5vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    padding-bottom: 100px;

    @media (min-width: 600px) {
        padding-bottom: 100px;
    }

    @media (min-width:1025px) {
        padding-bottom: 0px;
    }
`

function drawBackground() {
    const bgs = [...Array(4).keys()].map(index => `/bg/tokyo-${index + 1}.jpg`);
    return bgs[Math.floor(Math.random() * bgs.length)];
}

function TasksPage(props) {

    const taskManagerRef = useRef(null);
    const backgroundRef = useRef(drawBackground());

    function onCountdownDone(isBreak) {
        if (!isBreak) {
            taskManagerRef.current.increaseTaskProgress();
        }
    }

    return (
        <Container bg={backgroundRef.current}>
            <ContentContainer>
                <FragmentsContainer>
                    <CountdownContainer>
                        <CountdownFragment onCountdownDone={onCountdownDone} />
                    </CountdownContainer>
                    <TaskManagerContainer>
                        <TaskManagerFragment ref={taskManagerRef} />
                    </TaskManagerContainer>
                </FragmentsContainer>
                <Adsense />
            </ContentContainer>
            <LofiRecommendation />
            {/* <ConfeeLink /> */}
        </Container>
    );
}

export default TasksPage;