import styled from 'styled-components'
import SuccessIcon from '../success-icon/SuccessIcon';
import CardOption from './card-option/CardOption';

const Container = styled.div`
    font-size: 0.8em;
    text-align: center;
    color: #fff;
    background: rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    height: 4.5em;
    padding: 0 15px 0 15px;
    border-radius: 5px;
    left: auto !important;
`;

const Title = styled.div`
    width: calc(70% - 35px);
    text-align: start;
    height: fit-content;
    margin-left: 10px;
    font-size: 1.2em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

const Proggress = styled.div`
    width: 25%;
    font-weight: bold;
`

const CardOptionContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-right: 15px;
`

const ExecutedRounds = styled.span`
    font-size: 1.3em;
`;

function TaskCard(props) {

    return (
        <Container>
            <SuccessIcon complete={props.executedRounds >= props.expectedRounds} />
            <Title title={props.title}>{props.title}</Title>
            <Proggress>
                <ExecutedRounds>{props.executedRounds}</ExecutedRounds> / {props.expectedRounds}</Proggress>
            <CardOptionContainer>
                <CardOption onEdit={props.onEdit} onRemove={props.onRemove} />
            </CardOptionContainer>
        </Container>
    )
}

export default TaskCard;