import styled from "styled-components";
import Adsense from "../components/adsense/Adsense";
import Switch from "../components/switch/Switch";
import { useState } from "react";

const Container = styled.div`
    color: #f4f4f6;
    background-color: #f4f4f6;
    background-image: url('/bg/headphone-1.jpg');
    padding-top: 100px;
`;

const PresentationContainer = styled.h2`
    text-align: center;
    font-size: 1.2rem;
    margin: 0px;
    margin-bottom: 80px;
    padding: 0px 15px 0px 15px;
    font-weight: bold;

    @media (min-width: 600px) {
        padding: 0px 100px 0px 100px;
    }
`;

const VideoContainer = styled.div`
    height: calc(100vh * 0.45);
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
    position: relative;

    @media(min-width:1025px){
        width: 35%;
        margin-bottom: 0px;
    } 
`

const RecomendationsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    @media(min-width:1025px){
        flex-direction: row;
        margin-top: 140px;
    } 
`

const VideoDescription = styled.div`
    text-align: center;
    margin-bottom: 0.5rem;
    font-weight: bold;
    /* font-family: 'Courgette', cursive; */
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
`

const AdsenseContainer = styled.div`
    margin-top: 75px;
    padding-bottom: 70px;

    @media(max-width:480px){
        margin-top: 0;
    } 
`

const SwitchContainer = styled.div`
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    height: 75px;
`;

function LofiPage() {

    const lofiCafeContent = 'Lofi Cafe';
    const synthwaveContent = 'Synthwave'

    const rainContent = 'Rain';
    const brownNoiseContent = 'Brown noise';

    const [musicSelected, setMusicSelected] = useState(lofiCafeContent);
    const [soundEffectSelected, setSoundEffectSelected] = useState(rainContent);

    return (
        <Container>
            <PresentationContainer>
                To enhance your productivity and create a relaxed atmosphere, check the curated songs and sound effects below
            </PresentationContainer>
            <RecomendationsContainer>
                <VideoContainer>
                    <SwitchContainer>
                        <VideoDescription>Music</VideoDescription>
                        <Switch onSelected={setMusicSelected} leftValue={lofiCafeContent} rightValue={synthwaveContent} selected={musicSelected} />
                    </SwitchContainer>
                    {
                        musicSelected == lofiCafeContent
                            ? <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zr5JjgOMXeQ" title="Relax Quiet Cafe ☕ Cozy Coffee Shop with Lofi Hip Hop Mix - Beats to Study / Work to ☕ Lofi Café" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            : <iframe width="100%" height="100%" src="https://www.youtube.com/embed/y2ECgOhoDGs" title="Synthwave Cyberpunk Mixtape | Volume One" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    }
                </VideoContainer>
                <VideoContainer>
                    <SwitchContainer>
                        <VideoDescription>Sound effects</VideoDescription>
                        <Switch onSelected={setSoundEffectSelected} leftValue={rainContent} rightValue={brownNoiseContent} selected={soundEffectSelected} />
                    </SwitchContainer>
                    {
                        soundEffectSelected == rainContent
                            ? <iframe width="100%" height="100%" src="https://www.youtube.com/embed/mPZkdNFkNps" title="Rain Sound On Window with Thunder SoundsㅣHeavy Rain for Sleep, Study and Relaxation, Meditation" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            : <iframe width="100%" height="100%" src="https://www.youtube.com/embed/UE8VKhM9KBs" title="Brown Noise | Cockpit of an intergalactic Spacecraft" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    }
                </VideoContainer>
            </RecomendationsContainer>
            <AdsenseContainer>
                <Adsense />
            </AdsenseContainer>
        </Container>
    );
}

export default LofiPage;