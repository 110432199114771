import cryptoJs from "crypto-js";

const WORK_PLAN_KEY = 'WORK_PLANS';
const CURRENT_VERSION = '1.0.0';
const EXPORT_KEY = 'The Chill Work is the best pomodoro system ever';

async function saveWorkPlans(workPlans) {
    workPlans.version = CURRENT_VERSION;
    localStorage.setItem(WORK_PLAN_KEY, JSON.stringify(workPlans));
}

async function loadWorkPlans() {
    const workPlans = localStorage.getItem(WORK_PLAN_KEY)

    if (!workPlans || workPlans.trim().length === 0) {
        return undefined;
    }

    try {
        return JSON.parse(localStorage.getItem(WORK_PLAN_KEY));
    } catch (error) {
        localStorage.removeItem(WORK_PLAN_KEY);
        return null;
    }
}

async function exportWorkPlans() {
    const json = JSON.stringify(await loadWorkPlans()); // blob just as yours

    const data = cryptoJs.AES.encrypt(json, EXPORT_KEY);
    const blob = new Blob([data], { type: "application/octet-stream" });

    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = "work plan.chill";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

async function loadExternalWorkPlan() {

    return new Promise((result, reject) => {

        const input = document.createElement('input');
        input.type = 'file';

        input.addEventListener('change', event => {
            let files = event.target.files;
            if (!files.length) {
                alert('No file select');
                return;
            }
            let file = files[0];
            const reader = new FileReader();
            reader.onload = function (e) {
                try {
                    const decryptWorkPlan = cryptoJs.AES.decrypt(e.target.result, EXPORT_KEY).toString(cryptoJs.enc.Utf8);
                    result(JSON.parse(decryptWorkPlan));
                } catch (error) {
                    reject(error);
                }
            };
            reader.readAsText(file);
        });

        document.body.appendChild(input);
        input.click();
        document.body.removeChild(input);
    });
}

export { saveWorkPlans, loadWorkPlans, exportWorkPlans, loadExternalWorkPlan }


const workPlanSchema = {
    "version": "1.0.0",
    "active_workPlan": "Segunda-feira",
    "workPlans": {
        "Segunda-feira": {
            "name": "Segunda-feira",
            "tasks": [
                {
                    "title": "Study english",
                    "expectedRounds": 2,
                    "executedRounds": 1
                }
            ]
        },
        "Terça-feira": {
            "name": "Terça-feira",
            "tasks": [
                {
                    "title": "Play the guitar",
                    "expectedRounds": 2,
                    "executedRounds": 0
                }
            ]
        }
    },
    "orphan-tasks": [
        {
            "title": "Study english",
            "expectedRounds": 2,
            "executedRounds": 1
        }
    ]
}