import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../button/Button";
import RoundInput from "./round-input/RoundInput";

const Container = styled.div`
    background-color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
`

const TitleInput = styled.input`
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: 1.2em;
    margin-bottom: 20px;
    padding: 0px;

    :focus{
        outline: none;
    }
`

const RoundsContainer = styled.div`
    display: flex;
    width: 100%;
`;

const RoundsSeparatorContainer = styled.span`
    margin-left: 15px;
    margin-right: 15px;
    /* font-weight: bold; */
`

const ExecutedRoundsContainer = styled.div`
    display: flex;
    align-items: center;
`

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 25px;

    button {
        margin-right: 15px;
    }

    button:last-child{
        margin-right: 0px !important;
    }
`

const CardForm = React.forwardRef((props, ref) => {

    const [isEdition, setIsEdition] = useState(false);
    const [title, setTitle] = useState('');
    const [expectedRounds, setExpectedRounds] = useState(1);
    const [executedRounds, setExecutedRounds] = useState(0);
  
    useEffect(() => {
      setIsEdition(props.executedRounds !== undefined);
      setTitle(props.title ? props.title : '');
      setExpectedRounds(props.expectedRounds ? props.expectedRounds : 1);
      setExecutedRounds(props.executedRounds ? props.executedRounds : 0);
    }, [props]);

    return (
        <Container ref={ref}>
            <TitleInput placeholder="What are you planning to do?" autoFocus={true} value={title} onChange={evt => setTitle(evt.target.value)} />
            <RoundsContainer>
                {isEdition ?
                    (
                        <ExecutedRoundsContainer>
                            <RoundInput hoverTitle="Rounds finished" title="Rounds finished" rounds={executedRounds} onRoundChange={setExecutedRounds} />
                            <RoundsSeparatorContainer>|</RoundsSeparatorContainer>
                        </ExecutedRoundsContainer>
                    ) : null}
                <RoundInput hoverTitle="Rounds left" title="Rounds left" rounds={expectedRounds} onRoundChange={setExpectedRounds} />
            </RoundsContainer>

            <ButtonsContainer>
                <Button value="Cancel" onClick={props.onCancel} enabled />
                <Button value="Save" onClick={() => props.onCardsChanged({ title, executedRounds, expectedRounds })} isConfirmation enabled={title.trim().length > 0} />
            </ButtonsContainer>
        </Container>
    );
});

export default CardForm;