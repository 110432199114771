import { useEffect, useState } from "react";
import styled from "styled-components";

const CoffeeLink = styled.a`
    text-decoration: none;
    color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    bottom: 15px;
    display: ${props => props.show ? 'block' : 'none'};

    @media (min-width:600px){
        right: 40px;
        margin-right: unset;
        bottom: 1px;
        display: block;
    }

    @media (min-width:1025px){
        right: 40px;
        margin-right: unset;
    }
`

const CoffeeImg = styled.img`
    height: 41px !important;
    width: 174px !important;
`

function ConfeeLink(props) {

    const [show, setShow] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', onScrollEvent);
        return () => window.removeEventListener('scroll', onScrollEvent);
    }, [])

    function onScrollEvent() {
        if ((window.innerHeight + window.scrollY + 50) >= document.body.offsetHeight) {
            setShow(false);
        } else {
            setShow(true);
        }
    }

    return (
        <CoffeeLink show={show} title='Enjoying? What about buying me a coffee?' href="https://www.paypal.com/donate/?business=ZFJ5JCGGZUCBJ&no_recurring=1&item_name=I%27m+glad+you+came+here%21+Thank+you+very+much+for+your+support%2C+I+appreciate+that%21&currency_code=BRL" target="_blank">
            <CoffeeImg src="https://www.buymeacoffee.com/assets/img/custom_images/orange_img.png" alt="Buy Me A Coffee" title='Enjoying? What about buying me a coffee?' />
        </CoffeeLink>
    );
}

export default ConfeeLink;