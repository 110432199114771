import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
`;

const CountdownContainer = styled.span`
    font-size: 7em;
`;

const MinutesContainer = styled.span`
    font-weight: bold;
    font-size: 1.1em;
`;

const ColonContainer = styled.span`
    margin-left: 10px;
`

const workerScript = `
let intervalId;
self.onmessage = function (event) {
    const { data } = event;

    if (data.action === 'start') {
        startCountdown(data.duration);
    }

    if (data.action === 'stop') {
        stop();
    }
};

function stop() {
    clearInterval(intervalId);
}

function startCountdown(duration) {
    const startAt = new Date().getTime(); 

    intervalId = setInterval(() => {
        const now = new Date().getTime();
        const secondsFromStart = (now - startAt)/1000;
        const remainingSeconds = duration - secondsFromStart;

        if (secondsFromStart < duration) {
            self.postMessage({action:'tick', remainingSeconds: Math.floor(remainingSeconds)});
        } else {
            self.postMessage('complete');
            stop();
        }
    });
}
`;

function Countdown(props) {

    const [isActive, setIsActive] = useState(false);
    const [timeSeconds, setTimeSeconds] = useState(0);

    useEffect(() => {
        setTimeSeconds(props.seconds);
        setIsActive(props.active);
    }, [props.seconds])

    useEffect(() => {
        setIsActive(props.active);
    }, [props.active])

    useEffect(() => {

        const handleCountdownMessage = (event) => {
            const { data } = event;
            if (data.action === 'tick') {
                const newTimeSeconds = data.remainingSeconds;
                setTimeSeconds(newTimeSeconds);
                props.onTick && props.onTick(newTimeSeconds);
            } else if (data === 'complete') {
                props.onDone(); setTimeSeconds(props.seconds)
            }
        };

        const blob = new Blob([workerScript], { type: 'application/javascript' });
        const workerUrl = URL.createObjectURL(blob);

        const worker = new Worker(workerUrl);
        worker.onmessage = handleCountdownMessage;

        if (isActive && timeSeconds > 0) {
            worker.postMessage({ action: 'start', duration: timeSeconds });
        } else {
            worker.postMessage({ action: 'stop' });
        }

        return () => { worker.terminate(); };
    }, [isActive]);

    function formatToTwoDigits(number) {
        if (number.toString().length === 1) {
            return `0${number}`;
        }

        return number;
    }

    return (
        <Container>
            <CountdownContainer>
                <MinutesContainer>{formatToTwoDigits(Math.floor(timeSeconds / 60))}</MinutesContainer>
                <ColonContainer>:</ColonContainer>
                {formatToTwoDigits(timeSeconds % 60)}
            </CountdownContainer>
        </Container>
    );
}

export default Countdown;