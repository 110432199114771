import styled from "styled-components";
import TaskCard from "../task-card/TaskCard";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { swapItemsInArray } from "../../commons/arrays/Arrays";
import Scrollbars from "react-custom-scrollbars-2";
import ReactDOM from 'react-dom';

const Container = styled.div`
    max-height: 100%;
    height: 100%;
    width: 100%;
    overflow-y: auto;
`

const ItemContainer = styled.div`
    margin-bottom: 10px;
    user-select: none;
    /* left: auto !important;
    top: auto !important; */
`

const ScrollableContentContainer = styled.div`
    width: 96%;
    margin-left: 2%;
`;

const portal = document.createElement('div');
document.body.appendChild(portal);
function PortalAwareItem(props) {

    const card = props.card;
    const draggableProvided = props.provided;
    const snapshot = props.snapshot;
    const usePortal = snapshot.isDragging;

    const child = (
        <ItemContainer ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
            <TaskCard onEdit={props.onEdit} onRemove={props.onRemove} title={card.title} executedRounds={card.executedRounds} expectedRounds={card.expectedRounds} />
        </ItemContainer>
    );

    if (!usePortal) {
        return child;
    }

    return ReactDOM.createPortal(child, portal);
}

function CardHolder(props) {
    const cards = props.cards;

    function onDragEnd(dragResult) {
        const newCards = swapItemsInArray(cards, dragResult.source.index, dragResult.destination.index);
        props.onCardsChanged(newCards);
    }

    function onCardRemoved(cardIndex) {
        const newCards = [...cards];
        newCards.splice(cardIndex, 1);
        props.onCardsChanged(newCards);
    }

    return (
        <Container>
            <Scrollbars renderThumbVertical={(props) => <div {...props} style={{ backgroundColor: 'rgba(255,255,255, 0.8)' }} />}>
                <ScrollableContentContainer>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="task-card">
                            {
                                (provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            cards.map((card, index) =>
                                                <Draggable isDragDisabled={!props.enabled} key={`${index}-${card.title}`} draggableId={`${index}-${card.title}`} index={index}>
                                                    {(draggableProvided, draggableSnapshot) =>
                                                        <PortalAwareItem card={card} provided={draggableProvided} snapshot={draggableSnapshot} onEdit={() => props.onEditRequested(index)} onRemove={() => onCardRemoved(index)} />
                                                    }
                                                </Draggable>
                                            )
                                        }
                                        {provided.placeholder}
                                    </div>
                                )
                            }

                        </Droppable>
                    </DragDropContext>
                </ScrollableContentContainer>
            </Scrollbars>
        </Container>
    )
}

export default CardHolder;